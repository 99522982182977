import React from 'react';
import styled from 'styled-components';
export const BadgeArea = styled.div`
  display: flex;
  align-items: center;
`;
export const BadgeStyle = {
  Wrap: styled.b<BadgeProps>`
    display: flex;
    align-items: center;
    height: 20px;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    border-radius: 2px;
    padding: 0 6px;
    background: ${(p) => {
      switch (p.type) {
        case 'sky':
          return 'rgba(64, 162, 242, 0.70)';
        case 'red':
          return 'rgba(235, 88, 70, 0.70)';
        case 'orange':
          return 'rgba(255, 118, 0, 0.70)';
        case 'green':
          return 'rgba(24, 167, 84, 0.70)';
        default:
          return 'rgba(188, 188, 188, 0.60)';
      }
    }};
    &:not(:last-child) {
      margin-right: 4px;
    }
  `,
};

export interface BadgeProps {
  /** Badge 내 텍스트*/
  text?: string;
  /** Badge 색상 타입*/
  type?: 'sky' | 'red' | 'orange' | 'green';
}

/** Badge 컴포넌트*/
const Badge = ({ type, text }: BadgeProps) => {
  return <BadgeStyle.Wrap type={type}>{text}</BadgeStyle.Wrap>;
};

export default Badge;
