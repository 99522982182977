import React, { useEffect, useState } from 'react';
import Button from 'components/atoms/Button';
import RoundBadge from 'components/atoms/RoundBadge';
import { useRecoilState, useRecoilValue } from 'recoil';
import { confirmState, loadingState, ProjectDetailProps, projectDetailState } from 'store/Atoms';
import styled from 'styled-components';
import IcoSurveyStart from 'assets/images/button/ico-survey-start.svg';
import query from 'hooks/RQuery/utils';

const Style = {
  Wrap: styled.div`
    display: flex;
    gap: 6px;
  `,
  SurveyLabel: styled.div`
    /* width: 129px; */
    height: 48px;
    padding: 0 16px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
  `,
};

// (선택된) 사업의 회차 상태 변경 버튼
function SurveyStatus({ projectId }) {
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [loading, setLoading] = useRecoilState(loadingState);

  const projectDetail = useRecoilValue(projectDetailState);
  const [currentSurvey, setCurrentSurvey] = useState<any>({}); // 현재 회차 정보 (inProgressSurveyNo)
  const [targetSurvey, setTargetSurvey] = useState({ surveyId: '', status: '' }); // 업데이트 할 대상

  // 사업의 회차 리스트 조회
  const { data: projRounds } = query.project.useGetRounds(projectId);
  // 사업의 회차 상태 변경
  const { mutate: updateStatus } = query.survey.useUpdateSurveyStatus({ projectId });

  //READY(대기중),IN_PROGRESS (진행중), COMPLETED(회차 종료)
  const statusArr = ['READY', 'IN_PROGRESS', 'COMPLETED'];

  useEffect(() => {
    const { surveyList, inProgressSurveyNo } = projRounds || {}; // 회차 리스트 및 현재 회차
    if (surveyList?.length > 0) {
      const currentSurvey = surveyList.find((ele) => ele.surveyNo === inProgressSurveyNo); //현재 회차 정보
      setCurrentSurvey(currentSurvey); // state 저장

      // // 마지막 회차면서 진행중일때
      // if (currentSurvey?.surveyStatus === 'IN_PROGRESS' && surveyList?.length === inProgressSurveyNo) {
      //   setTargetSurvey({ surveyId: currentSurvey.surveyId, status: 'COMPLETED' }); // 사업 종료
      //   return;
      // }
      // 현재 대기중일때
      if (currentSurvey?.surveyStatus === 'READY') {
        setTargetSurvey({ surveyId: currentSurvey.surveyId, status: 'IN_PROGRESS' }); //현재 회차 진행중으로
      }
      // 현재 진행중일때
      if (currentSurvey?.surveyStatus === 'IN_PROGRESS') {
        setTargetSurvey({ surveyId: currentSurvey.surveyId, status: 'COMPLETED' }); // 현재 회차 종료
      }
    }
  }, [projRounds]);

  const onClickStart = () => {
    // 저장 전, 확인 팝업
    setConfirm({
      ...confirm,
      show: true,
      leftText: '취소',
      rightText: '예',
      message: confirmMessage,
      // width: 400,
      // messageSub: confirmMessageSub,
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: async () => {
        setConfirm({ ...confirm, show: false });
        setLoading({ show: true, text: '정보 양이 많을 경우 지연될수 있습니다.' }); // 로더 시작

        // 서버 요청
        await updateStatus(
          { surveyId: targetSurvey.surveyId, surveyStatus: targetSurvey.status },
          {
            onSuccess(data, variables, context) {
              setLoading({ show: false }); // 로더 종료
              setConfirm({
                ...confirm,
                show: true,
                leftText: undefined,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                },
                message: targetSurvey.status === 'IN_PROGRESS' ? '회차가 시작되었습니다.' : targetSurvey.status === 'COMPLETED' ? '회차가 종료되었습니다.' : '',
              });
              console.log(data);
              // 사업장 목록으로 화면 이동?
            },
            onError(error: any, variables, context) {
              setLoading({ show: false }); // 로더 종료
              console.log(error);
              const errMsg = error?.response?.data?.message;

              if (error.status === 504) {
                setConfirm({
                  ...confirm,
                  show: true,
                  leftText: undefined,
                  rightText: '확인',
                  rightFunc: () => {
                    setConfirm({ ...confirm, show: false });
                  },
                  message: '관리자에게 문의하세요',
                  isError: true,
                });
              } else {
                setConfirm({
                  ...confirm,
                  show: true,
                  leftText: undefined,
                  rightText: '확인',
                  rightFunc: () => {
                    setConfirm({ ...confirm, show: false });
                  },
                  message: errMsg || '요청 실패',
                  isError: true,
                });
              }
            },
          },
        );
      },
    });
  };

  const confirmMessage =
    currentSurvey?.surveyStatus === 'READY'
      ? `'${projectDetail.projectName}' \n ${currentSurvey?.surveyNo}회차 판독을 시작하시겠습니까?`
      : currentSurvey?.surveyStatus === 'IN_PROGRESS'
        ? `'${projectDetail.projectName}' \n ${currentSurvey?.surveyNo}회차를 종료하시겠습니까?`
        : '';
  const confirmMessageSub = currentSurvey?.surveyStatus === 'READY' ? `판독 작업이 시작되면 취소할 수 없으니, \n 필지 및 작물이 올바른 정보인지 다시 한 번 확인해 주세요.` : '';
  const buttonText = currentSurvey?.surveyStatus === 'READY' ? '회차 시작' : currentSurvey?.surveyStatus === 'IN_PROGRESS' ? '회차 종료' : '';

  return (
    <Style.Wrap>
      <Style.SurveyLabel>
        <RoundBadge num={currentSurvey?.surveyNo || 0} />
        {currentSurvey?.surveyStatus === 'READY' ? '대기중' : currentSurvey?.surveyStatus === 'IN_PROGRESS' ? '진행중' : currentSurvey?.surveyStatus === 'COMPLETED' ? '회차종료' : '-'}
      </Style.SurveyLabel>
      {buttonText && <Button width={130} text={buttonText} icon={IcoSurveyStart} $iconSize={24} $iconSpacing={4} onClick={onClickStart} />}
      {/* <Button width={130} text={'회차 시작'} icon={IcoSurveyStart} $iconSize={24} $iconSpacing={4} onClick={onClickStart} /> */}
    </Style.Wrap>
  );
}

export default SurveyStatus;
