import React, { Fragment, useEffect, useRef, useState } from 'react';

import { DateObject } from 'react-multi-date-picker';
import { findLastIndex, formatDate2, formatNumber } from 'util/global';

import { CropInfoSt } from './_style';
import IcoInfo from 'assets/images/button/ico-info.svg';
import IcoInfoDisabled from 'assets/images/button/ico-info-disabled.svg';
import imageNo from 'assets/images/image-no.png';

import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { confirmState, popupLargeImgState, stdgCdState, toastPopState, userInfoState } from 'store/Atoms';
import query from 'hooks/RQuery/utils';
import usePopupToggle from 'hooks/custom/usePopupToggle';
import useOnChange from 'hooks/useOnChange';
import Button from 'components/atoms/Button';
import Badge, { BadgeArea } from 'components/atoms/Badge';
import LabelValue from 'components/atoms/LabelValue';
import Pagination from 'components/atoms/Pagination';
import Checkbox from 'components/atoms/Checkbox';
import PopupLotInfo from '../../../popup/PopupLotInfo';
import CropFilter from './CropInfoBox/CropFilter';
import ActiveArea from './CropInfoBox/ActiveArea';
import DataEnterArea from './CropInfoBox/DataEnterArea';
import ImageWithRetry from './CropInfoBox/ImageWithRetry';
import { useCheckboxHandler } from 'hooks/useCheckboxHandler';

// 1뎁스 필터 - 필드
const initFormField = {
  전체: true,
  논: true,
  밭: true,
  과수: true,
  시설: true,
  비경지: true,
};

// 2뎁스 필터 - status
//   const initFormStatus = {
//   전체: false,
//   미판독: isWorker,
//   판독: !isWorker,
//   검수완료: false,
//   검수자확인: false,
//   };

// 작물정보 판독하는 팝업
function CropInfoBox({ close, round, isSingleLot, setIsSingleLot, singleFarmMapId, isAdmin, onChangeFeatureStatus }) {
  const cardRefs = useRef<any[]>([]); // 카드 리스트 스크롤 조정
  const surveyId = round.find(({ active }) => active)?.surveyId; // 현재 진행중인 회차 id
  const beforeSurveyId = round[round.findIndex(({ active }) => active) - 1]?.surveyId; // 이전 회차 id
  const isWorker = sessionStorage.getItem('auth') === 'ROLE_WORKER'; // 판독자 웹 여부
  // status의 단계 (0,1,2)
  const statusArr = [
    {
      kor: '미판독',
      status: 'NONE',
      initActive: isWorker,
    },
    {
      kor: '판독',
      status: 'FILL_RATE',
      initActive: !isWorker,
    },
    {
      kor: '검수완료',
      status: 'FILL_RATES',
      initActive: false,
    },
  ] as const;
  type StatusType = (typeof statusArr)[number]['status'];
  type InitFormStatus = {
    [key in StatusType]: boolean;
  };
  const getStatus = (targetKor) => statusArr?.find(({ kor }) => kor === targetKor)!.status;
  const initStatus: StatusType = isWorker ? getStatus('미판독') : getStatus('판독'); // 최초 status 지정 ('NONE' or 'FILL_RATE')
  const initFormStatus: InitFormStatus = statusArr.reduce((acc, { kor, initActive }) => {
    acc[kor] = initActive;
    return acc;
  }, {} as InitFormStatus);

  const surveyCropList = round?.find(({ active }) => active)?.surveyCropList; // 회차데이터 내의 작물코드(필터-작물에서 사용)

  const [initFilterList, setInitFilterList] = useState({
    formField: initFormField,
    formStatus: initFormStatus,
    formCrop: {}, // 회차의 작물코드로 업데이트 함
    //  formCrop: initFilterCrop,
  });

  const [stdgCdInfo, setStdgCdInfo] = useRecoilState(stdgCdState); // recoil 법정동코드
  const [popupState, setPopupState] = useState({
    lotInfo: false, // 필지 상세 팝업
  });
  const onTogglePopup = usePopupToggle(popupState, setPopupState); // 팝업 토글 hook

  const [allChecked, setAllChecked] = useState(false); //카드 전체체크여부 변수
  const [cardData, setCardData] = useState<any[]>([]); // 카드 데이터
  const [lotInfo, setLotInfo] = useState({}); // 필지 상세 버튼에서 보여줄 내용

  const [surveyFarmMapId, setSurveyFarmMapId] = useState(''); // 선택된 필지 아이디(판독 필터일때)
  const [lastCheckedId, setLastCheckedId] = useState<string>(''); // 지도 이동할 필지(선택된 필지 아이디)

  const [surveyDatas, setSurveyDatas] = useState<any[]>([]); // 판독 정보 저장 (마지막 선택된 필지의 정보)

  const [formField, setFormField, onChangeField] = useOnChange(initFilterList.formField); // [필터]필드
  const [formStatus, setFormStatus, onChangeStatus] = useOnChange(initFilterList.formStatus); // [필터] 미판독,판독,검수완료
  const [formCrop, setFormCrop, onChangeCrop] = useOnChange(initFilterList.formCrop); // [필터]작물 (displayName)

  const isAllclsfNms = formField['전체'];
  const clsfNms = isAllclsfNms ? [] : Object.keys(formField)?.filter((key) => key !== '전체' && formField[key]); // [필터]필드 - '전체'항목 제외(서버요청용)
  const [surveyCropIds, setSurveyCropIds] = useState<any>([]); // 미판독일때: 빈배열[] | 판독일때: 작물id[] // 전체일때는 빈배열 []
  const [status, setStatus] = useState<any>(initStatus); // 'NONE'(미판독) , FILL_RATE, FILL_RATES, INSPECT (판독, 검수완, 확인완)

  //   const [remainStatus, setRemainStatus] = useState<null | boolean | 'NONE'>(null); // 카드 위에 잔여량 유무 버튼 (null: 둘다선택, true:유, false:무, 'NONE': 둘다선택 X )
  const [remainStatus, setRemainStatus] = useState<boolean>(false); // 카드 위에 잔여량 유무 버튼 (true:입력, false:미입력)
  const [inspectStatus, setInspectStatus] = useState<boolean>(false); // 재검토요청 여부 (true:요청상태, false:미요청상태)
  //   const inspectStatus = null; // 검수자확인 여부 (null: 둘다선택, true:유, false:무)

  const [currentPage, setCurrentPage] = useState(1); // pageNum (1페이지)
  const size = 20;

  const { data: prevSurveyCrops } = query.survey.useGetCrops(beforeSurveyId); //(이전회차Id) 회차별 작물코드(이전회차 데이터에서 사용)
  const { data: surveyCrops } = query.survey.useGetCrops(surveyId); //회차별 작물코드(우측 점유율 목록에서 사용)

  const [initReady, setInitReady] = useState(false);
  const {
    data: surveyLots,
    refetch: refetchGetLots,
    error: surveyLotsError,
  } = query.survey.useGetLots({
    initReady,
    surveyId,
    stdgCd: stdgCdInfo?.code,
    clsfNms,
    surveyCropIds, // surveyCropIds:[],
    status,
    remainStatus,
    inspectStatus,
    pageNum: currentPage - 1,
    size,
    isSingleLot,
  }); // 회차별 필지 목록(paging)|쿼리키: surveyId, pageNum, stdgCd(법정동코드)

  // 회차별 필지 단일 조회
  const { data: surveySingleLot, refetch: refetchGetSingleLot } = query.survey.useGetSingleLot({ surveyId, surveyFarmMapId, isSingleLot });

  // 회차별 필지 판독 정보 조회(판독 필터일때)
  const { data: surveyData, refetch: refetchSurveyData } = query.survey.useGetSurveyData({ surveyFarmMapId });

  // 초기 한번, 필지 정보 요청 (surveyCropIds 업데이트 후 자동으로 데이터 요청 X (쿼리키에 포함 안시킴))
  useEffect(() => {
    if (initReady) {
      console.log('refetch cause not worker');
      setTimeout(() => {
        //지도에서 필지클릭으로 접근했을 때
        if (!isSingleLot) {
          refetchSurveyLots();
        }
      });
    }
  }, [initReady, isSingleLot]);

  /** [공통] 필지 목록 재요청 */
  const refetchSurveyLots = async () => {
    // 그 외
    if (isSingleLot) {
      setTimeout(() => {
        refetchGetSingleLot();
      }, 100);
    } else {
      await setCurrentPage(1); // PageNum 1페이지로
      setTimeout(() => {
        refetchGetLots(); // 데이터 재요청
      }, 100);
    }
  };

  // 필터 초기화 - 작물도 추가
  const resetFilter = () => {
    setFormField(initFilterList?.formField); // [논,밭,과수,시설]
    setFormStatus(initFilterList?.formStatus); // 미판독, 판독, 검수완료
    setFormCrop(initFilterList?.formCrop); // 작물 ids []
  };

  // 회차 변경마다 필터초기화 (TODO: 판독자 / 검수자 다르게 해야함) -> 쿼리키에 포함해야함
  // (State는 업데이트 되는데, surveyId가 변경되는 시점에 state값이 이전값임.)
  //   console.log('filter1', clsfNms);
  //   console.log('filter2', status);
  //   console.log('filter3', surveyCropIds);

  // 판독 팝업 닫힐때 데이터 비우기
  useEffect(() => {
    return () => {
      setCardData([]);
    };
  }, []);

  // 선택된카드의 판독 데이터 저장 (마지막 선택 항목 1개만 저장) - 카드id 없으면 요청 안함
  useEffect(() => {
    if (cardData?.filter(({ active }) => active)?.length > 0) {
      if (surveyData) {
        setSurveyDatas([surveyData]);
      }
    } else {
      setSurveyDatas([]);
    }
  }, [surveyData, cardData]);

  // 원경, 근경 썸네일 이미지 유효성 검사 결과 리턴
  const checkUrlFetch = async (card) => {
    const wideThumbArr = card.imageList.wideImageList?.map((ele) => ele.thumbnail) || [];
    const zoomThumbArr = card.imageList.zoomImageList?.map((ele) => ele.thumbnail) || [];
    const allUrls = [...wideThumbArr, ...zoomThumbArr]; // 배열 합치기

    const results = await Promise.all(
      allUrls.map(async (url) => {
        try {
          const response = await fetch(url, { method: 'HEAD' });

          if (response.ok) {
            return { url, imgStatus: 'valid' }; // 유효한 경우
          } else {
            return { url, imgStatus: 'invalid' }; // 비유효한 경우
          }
        } catch (error) {
          return { url, imgStatus: 'invalid' }; // 요청 실패
        }
      }),
    );

    return results;
  };

  // 카드 데이터 맵핑
  const updateCardData = async (orginData, isFirst) => {
    const updatedData = await Promise.all(
      // 원본 데이터 맵핑
      orginData.map(async (card, idx) => {
        const active = isFirst ? idx === 0 : card.active; // 중간 업데이트엔 카드 active 유지
        try {
          const thumbnailStatuses = await checkUrlFetch(card); // response.ok 여부 확인
          const hasInvalid = thumbnailStatuses?.some(({ imgStatus }) => imgStatus === 'invalid');

          if (hasInvalid) {
            return { ...card, imgStatus: 'invalid', active }; // 유효하지 않은 경우
          } else {
            return { ...card, imgStatus: 'valid', active }; // 유효한 경우
          }
        } catch (error) {
          console.log('error wide:', error);
          return { ...card, imgStatus: 'error', active }; // 요청 실패
        }
      }),
    );
    // setState 상태 업데이트
    setCardData(updatedData);
  };

  //(비동기 작업 포함) 필지 목록
  useEffect(() => {
    //  if (surveyLots) {
    //    if (surveyLots?.content?.length > 0) {
    //      updateCardData(surveyLots?.content, true); // 저장!!
    //    }
    //  }
  }, [surveyLots]);

  // TEST: 임시 리렌더링 처리
  const [updateTime, setUpdateTime] = useState<any>(null);

  // 데이터 새로고침 버튼 이벤트 (기존 active 카드 유지)
  //   const resetCard = (e, card, index) => {
  //     e.stopPropagation(); // 버블링 막기(onClickCard 이벤트 막기)

  //     updateCardData(cardData, false);
  //     setUpdateTime(new Date()); // 이미지 태그 업데이트 시간 [src변경시, updateTime변경시]
  //   };

  const refetchCardData = (e) => {
    e.stopPropagation();
    //refetch
  };

  // 필지 목록 (active 포함) - 카드 형식으로 변경
  useEffect(() => {
    if (surveyLots?.content?.length) {
      // const active = isFirst ? idx === 0 : card.active; // 중간 업데이트엔 카드 active 유지
      setCardData(surveyLots?.content?.map((card, idx) => ({ ...card, active: idx === 0 }))); // 첫번째 카드 active
    } else {
      setCardData([]); // 카드 데이터 길이 0, undefined 상황
    }
  }, [surveyLots, surveyLotsError]);

  useEffect(() => {
    if (isSingleLot) {
      setSurveyFarmMapId(singleFarmMapId);
      if (surveySingleLot) {
        setCardData([{ ...surveySingleLot, active: true }]);
      } else {
        setCardData([]);
      }
    }
  }, [surveySingleLot]);

  // [초기] 필터 - 작물 체크박스형태
  useEffect(() => {
    function getInitFormCrop() {
      let obj = {};
      // 회차 정보에 작물코드 데이터 있을때
      if (surveyCropList?.length > 0) {
        obj['전체'] = !isWorker; // '전체' 옵션 추가
        surveyCropList?.map((ele) => (obj[ele.cropName] = !isWorker)); // 판독자는 false, 그외 true
      }
      return obj;
    }

    const initFormCrop: {} = getInitFormCrop();

    setFormCrop(initFormCrop); // 작물 체크박스
    setInitFilterList((prev) => ({
      ...prev,
      formCrop: initFormCrop,
    })); // 초기 데이터 저장
  }, [surveyCropList, isWorker]);

  // formCrop 변경시 > [서버 전송용] surveyCropIds (작물 아이디)
  useEffect(() => {
    function getSurveyCropIds() {
      let ids: any[] = [];
      let cropKeys: string[] = Object.keys(formCrop)?.filter((key) => key !== '전체' && formCrop[key]); // 체크된것 (한글cropName)
      let cropIds: string[] = surveyCropList.map((ele) => (cropKeys.includes(ele.cropName) ? ele.surveyCropId : null))?.filter((ele) => ele !== null);
      // 작물 전체 선택됐을때
      if (surveyCropList?.length === cropKeys?.length) {
        ids = [];
      } else {
        ids = cropIds;
      }
      return ids;
    }

    if (surveyCropList?.length > 0) {
      let surveyCropIds = getSurveyCropIds();

      setSurveyCropIds(surveyCropIds); // crop ids 저장
      setInitReady(true); // 초기 state 준비 완료 > 필지 refetch
    }
  }, [surveyCropList, formCrop]);

  // [서버 필터] status (수정: 여러개 -> 단일 선택)
  useEffect(() => {
    let curStatus: any = '';
    Object.entries(formStatus)?.map(([key, value]) => {
      if (value === true) {
        curStatus = statusArr?.find((ele) => ele.kor === key)?.status;
      }
    });
    setStatus(curStatus); // status 필터 상태 저장 - 서버
  }, [formStatus]);

  /* 고려사항
   1) 복수이미지를 가진 카드 데이터는 단독 선택만 가능
   (전체선택 체크박스/카드 개별 체크박스에서 고려해야함)
  */
  // ------------ 카드 선택 ------------//

  //  선택된 카드 변경될 때마다 스크롤 조정
  useEffect(() => {
    const isSingle = cardData?.filter(({ active }) => active)?.length === 1;
    // 1장 선택
    if (isSingle) {
      const activeCardIndex = findLastIndex(cardData, ({ active }) => active); // 현재 활성화된 카드(마지막)
      if (activeCardIndex >= 0 && cardRefs.current[activeCardIndex]) {
        cardRefs.current[activeCardIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [cardData]);

  // 선택된 카드 변경될 때마다 id 저장
  useEffect(() => {
    const activeCard = cardData?.filter(({ active }) => active);
    // 1장 선택
    if (activeCard?.length === 1) {
      setSurveyFarmMapId(activeCard[0]?.surveyFarmMapId); // 판독 정보 조회
      setLastCheckedId(activeCard[0]?.surveyFarmMapId); // 선택한 필지로 [지도] 이동하기위해
    }
  }, [cardData]);

  // 전체 선택 상태 동기화
  useEffect(() => {
    const activeCard = cardData?.filter(({ active }) => active);
    const duplicatedCard = activeCard?.filter(({ imageList }) => imageList?.wideImageCount > 1 || imageList?.zoomImageCount > 1);
    // (총 카드 길이) - (중복 카드 길이) = active 카드 길이
    if (cardData?.length - duplicatedCard?.length === activeCard?.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [cardData]);

  // 카드 하나의 데이터만 업데이트
  const updateSingleCard = (response) => {
    const targetId = response?.surveyFarmMapId;
    setCardData((prev) => [...prev].map((ele) => (ele.surveyFarmMapId === targetId ? { ...ele, ...response } : ele)));
  };

  // 다음 카드 활성화 이벤트
  const selectNextCard = async () => {
    let activeCard = cardData?.filter(({ active }) => active);
    // 활성화된 카드 없을때 첫번째 항목 active
    if (activeCard?.length === 0) {
      setCardData((prev) => [...prev]?.map((ele, idx) => ({ ...ele, active: idx === 0 })));
      return;
    }

    let curIdx = cardData?.findIndex(({ active }) => active); // 기존 활성화 카드 인덱스 찾기

    const isLastPage = surveyLots?.last;
    const isLastItem = surveyLots?.totalElements === (currentPage - 1) * size + (curIdx + 1);
    // 현재 페이지 마지막 항목일때(다음 페이지 데이터 요청)
    if ((curIdx + 1) % size === 0 && !isLastPage) {
      await setCurrentPage(currentPage + 1);
      setCardData((prev) => [...prev]?.map((ele, idx) => ({ ...ele, active: idx === 0 })));
      return;
    }
    // 다음 카드 index 활성화(마지막 항목이 아닐때)
    if (!isLastItem) {
      setCardData((prev) => [...prev]?.map((ele, idx) => ({ ...ele, active: idx === curIdx + 1 })));
      return;
    }
  };

  // 카드 선택 (전체 영역 이벤트)
  const onClickCard = (e, data) => {
    const checked = true; // 무조건 active
    const surveyFarmMapId = data?.surveyFarmMapId;

    // 체크박스(label 포함) 영역 아닐때
    if (e.target.tagName !== 'INPUT') {
      // 카드 개별 선택
      setCardData((prev) => [...prev]?.map((ele) => ({ ...ele, active: ele.surveyFarmMapId === surveyFarmMapId ? checked : false })));

      if (checked) {
        setLastCheckedId(surveyFarmMapId); // 마지막 선택한 필지로 [지도] 이동하기위해
      }

      // status 판독 단계 체크
      let statusStep = statusArr?.findIndex(({ status }) => status === data?.status);

      // 미판독 이후 상태일때
      if (statusStep > 0) {
        if (checked) {
          setSurveyFarmMapId(surveyFarmMapId); // 클릭한 필지 Id 저장 -> 판독 데이터 요청 후 저장됨 surveyDatas
        }
      }
    }
  };

  // 체크 박스 영역(선택 or 선택 해제)
  const onCheckCard = (e, data) => {
    e.stopPropagation(); // 버블링 막기(onClickCard 이벤트 막기)

    const checked = !data?.active; // 선택한 카드의 이전상태 보고 채크
    const surveyFarmMapId = data?.surveyFarmMapId;

    // 판독자일떄 (복수 카드 선택 가능)
    if (isWorker) {
      const activeCard = cardData?.filter(({ active }) => active);
      const isPrevCard = cardData?.filter(({ active }) => active)?.length > 0;
      const duplicatedCard = activeCard?.filter(({ imageList }) => imageList?.wideImageCount > 1 || imageList?.zoomImageCount > 1)?.length > 0;

      setCardData((prev) =>
        [...prev]?.map((ele) => {
          if (ele.surveyFarmMapId === surveyFarmMapId) {
            /* 선택 조건 추가 */
            if (checked) {
              //이미 선택된 카드가 있고, 현재 선택한 카드가 중복이미지가 있음
              if (isPrevCard && (ele.imageList?.wideImageCount > 1 || ele.imageList?.zoomImageCount > 1)) {
                alert('중복 사진이 있는 필지는 단일 선택만 가능합니다.');
                return { ...ele, active: false };
              }
              //이미 중복이미지 가진 카드가 선택됨
              if (duplicatedCard) {
                alert('중복 사진이 있는 필지는 단일 선택만 가능합니다.');
                return { ...ele, active: false };
              }
            }
            // 기본 동작(토글)
            return { ...ele, active: checked };
          }
          return ele;
        }),
      );
    } else {
      // 판독자 아닐때 - 체크 or 체크해제
      setCardData((prev) => [...prev]?.map((ele) => ({ ...ele, active: ele.surveyFarmMapId === surveyFarmMapId ? checked : false })));
    }

    if (checked) {
      setLastCheckedId(surveyFarmMapId); // 마지막 선택한 필지로 [지도] 이동하기위해
    }

    // status 판독 단계 체크
    let statusStep = statusArr?.findIndex(({ status }) => status === data?.status);

    // 미판독 이후 상태일때
    // ? 조건: data에 판독된 적이 있다면 저장
    if (statusStep > 0) {
      if (checked) {
        setSurveyFarmMapId(surveyFarmMapId); // 클릭한 필지 Id 저장 -> 판독 데이터 요청 후 저장됨 surveyDatas
      } else {
        setSurveyFarmMapId(''); // 필지 Id 초기화(쿼리키 업데이트?)
        // 판독 데이터 삭제 - 불필요?
        setSurveyDatas((prev) => [...prev]?.filter((ele) => ele.surveyFarmMapId !== surveyFarmMapId));
      }
    }
  };

  //카드 전체선택 (중복 이미지 카드는 선택 불가)
  const onClickAllCard = (e) => {
    const { checked } = e.target;
    setAllChecked(checked);

    setCardData((prev) =>
      [...prev]?.map((ele, idx) => {
        const isDuplicated = ele.imageList?.wideImageCount > 1 || ele.imageList?.zoomImageCount > 1;
        if (isDuplicated) {
          //중복 이미지 카드 - false
          return { ...ele, active: false };
        }
        return { ...ele, active: checked };
      }),
    );

    const noDuplicatedCardData = cardData.filter(({ imageList }) => !(imageList?.wideImageCount > 1 || imageList.zoomImageCount > 1));
    setLastCheckedId(noDuplicatedCardData[noDuplicatedCardData.length - 1].surveyFarmMapId);
  };

  // 잔여량 유 / 무 필터 버튼
  const onChangeRemainStatus = async (value) => {
    // 현재 remainStatus가 true나 false값을 가졌을 때
    //  if (remainStatus === true || remainStatus === false) {
    //    //현재 remainStatus와 다른 버튼을 눌렀을 때 전체 표시
    //    if ((remainStatus === true && value === false) || (remainStatus === false && value === true)) {
    //      await setRemainStatus(null);
    //    } else {
    //      //현재 remainStatus와 같은 버튼을 누르면 이상한 값 보내기(리스트 안나오게)
    //      await setRemainStatus('NONE');
    //      // await setCardData([]);
    //    }
    //  } else if (remainStatus === null) {
    //    //현재 remainStatus가 전체인데 하나를 체크 해제
    //    await setRemainStatus(!value);
    //  } else if (remainStatus === 'NONE') {
    //    await setRemainStatus(value);
    //  }

    await setRemainStatus(value);

    // 필터 저장 후, 데이터 재요청
    setTimeout(() => {
      if (isSingleLot) {
        refetchGetSingleLot();
      } else {
        refetchSurveyLots();
      }
    }, 100);
  };

  // 재검토요청 필터
  const onChangeInspectStatus = async (value) => {
    await setInspectStatus(value);

    // 필터 저장 후, 데이터 재요청
    setTimeout(() => {
      if (isSingleLot) {
        refetchGetSingleLot();
      } else {
        refetchSurveyLots();
      }
    }, 100);
  };

  // 필지카드 - 상세버튼 클릭
  const onClickCardDetail = async (info) => {
    await setLotInfo(info); // 정보 저장 후
    setTimeout(() => {
      onTogglePopup('lotInfo'); // 팝업 오픈
    }, 100);
  };

  // 선택된 카드 개수(전체 선택 체크박스 옆에 표시용)
  const selectedCardLength = cardData?.filter(({ active }) => active)?.length;

  // 미판독만 선택되었는지 확인
  //! 수정하기 : 라디오로 변경되면서 불필요해짐
  let checkedKeys: any[] = Object.entries(formStatus)
    ?.map(([key, value]) => (value ? key : undefined))
    ?.filter((ele) => ele);
  const isOnlyNoneChecked = checkedKeys?.length === 1 && checkedKeys?.includes('미판독');

  console.log(cardData);
  return (
    <>
      {/* <ImagePreloader srcArray={originUrls} /> */}
      <CropInfoSt.Wrap
        // className={!cardData.filter(({ active }) => active).length ? 'hide' : undefined}
        className=""
        $isAdmin={isAdmin}
        $isSingleLot={isSingleLot}
      >
        <CropInfoSt.Header>
          <p className="title">작물 정보</p>
          <span
            className="closeBtn"
            onClick={() => {
              close();
              setIsSingleLot(false);
              setSurveyFarmMapId('');
            }}
          ></span>
        </CropInfoSt.Header>
        <CropInfoSt.ContainerWrap>
          <CropInfoSt.Container className={!cardData.filter(({ active }) => active).length ? 'hide' : undefined}>
            {!isSingleLot ? (
              <CropFilter
                onChangeField={onChangeField}
                formField={formField}
                setFormField={setFormField}
                //  onChangeBefore={onChangeBefore}
                onChangeStatus={onChangeStatus}
                formStatus={formStatus}
                setFormStatus={setFormStatus}
                //  formBefore={formBefore}
                onChangeCrop={onChangeCrop}
                formCrop={formCrop}
                setFormCrop={setFormCrop}
                refetchSurveyLots={refetchSurveyLots}
                resetFilter={resetFilter}
                setRemainStatus={setRemainStatus}
                initFilterList={initFilterList}
                //  updateCropIds={updateCropIds}
              />
            ) : null}

            <CropInfoSt.ContentWrap>
              <CropInfoSt.Content style={isSingleLot ? { height: 'calc(100% - 12px)' } : {}}>
                {/* 전체 선택 체크박스 */}
                {sessionStorage?.getItem('auth') === 'ROLE_WORKER' && !isSingleLot ? (
                  <div className="selectAll">
                    <Checkbox text="전체 선택" id="AllCard" name="all" checked={allChecked} onChange={onClickAllCard} disabled={!cardData.filter(({ imageList }) => !(imageList?.wideImageCount > 1 || imageList.zoomImageCount > 1)).length} />

                    <div className="selectWrap">
                      <p>선택</p>
                      <span className="num">{selectedCardLength}</span>
                    </div>
                  </div>
                ) : null}
                {/* 필터 버튼 및 페이지 표기 */}
                {!isSingleLot ? (
                  <div className="filterWrap">
                    <div className="btnWrap">
                      {!isOnlyNoneChecked && (
                        <>
                          {/*<button className={`${remainStatus === null ? 'active' : ''}`} onClick={() => onChangeRemainStatus(null)}>*/}
                          {/*  전체*/}
                          {/*</button>*/}
                          <button className={`${remainStatus === true ? 'active' : ''}`} onClick={() => onChangeRemainStatus(!remainStatus)}>
                            잔여량
                          </button>
                          <button className={`${inspectStatus === true ? 'active' : ''}`} onClick={() => onChangeInspectStatus(!inspectStatus)}>
                            재검토요청
                          </button>

                          {/* <button className={`${remainStatus === true || remainStatus === null ? 'active' : ''}`} onClick={() => onChangeRemainStatus(true)}>
                            잔여량 입력
                          </button>
                          <button className={`${remainStatus === false || remainStatus === null ? 'active' : ''}`} onClick={() => onChangeRemainStatus(false)}>
                            잔여량 미입력
                          </button> */}
                        </>
                      )}
                    </div>
                    <div className="totalWrap">
                      {/* <span>조회 결과 / </span> */}
                      {/* <span className="total">총 {remainStatus === 'NONE' ? 0 : formatNumber(surveyLots?.totalElements)}건</span> */}
                      <span className="total">총 {formatNumber(surveyLots?.totalElements)}건</span>
                    </div>
                  </div>
                ) : null}
                {/* 카드 리스트 영역 */}
                <CropInfoSt.CardWrap className="cScroll" style={isSingleLot ? { height: 218 } : sessionStorage.getItem('auth') !== 'ROLE_WORKER' ? { height: 'calc(100% - 49px)' } : {}}>
                  {
                    // remainStatus !== 'NONE' &&
                    cardData?.length > 0 ? (
                      cardData?.map((item: any, index) => {
                        return (
                          <CropInfoSt.Card
                            style={{ position: 'relative' }}
                            key={`card_${item.mapId}`}
                            ref={(el) => (cardRefs.current[index] = el)}
                            className={item.active ? 'active' : undefined}
                            onClick={(e) => {
                              onClickCard(e, item);
                            }}
                          >
                            <CropInfoSt.CardHead>
                              {!isSingleLot ? (
                                <>
                                  <Checkbox
                                    // text={item.mapId}
                                    fontSize={16}
                                    id={item.surveyFarmMapId}
                                    $sameColor={true}
                                    onClick={(e) => {
                                      //   e.stopPropagation();
                                      onCheckCard(e, item);
                                    }}
                                    //   onChange={(e) => onClickCard(e)}
                                    checked={item.active}
                                  />
                                  <p style={{ color: '#001a21', fontSize: 16, fontWeight: 500, marginRight: 'auto', marginLeft: 10 }}>{item.mapId}</p>
                                </>
                              ) : (
                                <p style={{ color: '#001a21', fontSize: 16, fontWeight: 500 }}>{item.mapId}</p>
                              )}
                              <Button
                                $buttonType={'small'}
                                text={'상세'}
                                $iconSize={16}
                                icon={IcoInfo}
                                $iconDisabled={IcoInfoDisabled}
                                $iconSpacing={2}
                                onClick={(event) => {
                                  event.stopPropagation(); // 버블링을 막음
                                  onClickCardDetail(item?.info);
                                }}
                              />
                            </CropInfoSt.CardHead>
                            <CropInfoSt.CardCont>
                              <CropInfoSt.CardImageArea>
                                <div className="imageWrap">
                                  {item.imageList?.zoomImageCount > 1 && <div className={`imgCount active`}>{item.imageList?.zoomImageCount}</div>}
                                  {item.imageList?.zoomImageList?.length > 0 ? (
                                    <ImageWithRetry src={item.imageList?.zoomImageList[item.imageList?.zoomImageList?.length - 1]?.thumbnail} alt={item.mapId + '_근경'} noText />
                                  ) : (
                                    <img width={'100%'} src={imageNo} alt={'NoImage'} />
                                  )}
                                </div>
                                <div className="imageWrap">
                                  {item.imageList?.wideImageCount > 1 && <div className={`imgCount active`}>{item.imageList?.wideImageCount}</div>}
                                  {item.imageList?.wideImageList?.length > 0 ? (
                                    <ImageWithRetry src={item.imageList?.wideImageList[item.imageList?.wideImageList?.length - 1]?.thumbnail} alt={item.mapId + '_원경'} noText />
                                  ) : (
                                    <img width={'100%'} src={imageNo} alt={'NoImage'} />
                                  )}
                                </div>
                              </CropInfoSt.CardImageArea>
                            </CropInfoSt.CardCont>
                            <CropInfoSt.CardFoot>
                              <CropInfoSt.CardLabelValueArea>
                                <LabelValue $ellipsis={true} label={'판독 회차'} value={item.checkCount} $justifyContent={'space-between'} $valueJustifyContent={'flex-end'} />
                                <LabelValue
                                  $justifyContent={'space-between'}
                                  $valueJustifyContent={'flex-end'}
                                  label={'판독'}
                                  value={
                                    <BadgeArea>
                                      {item.status === 'NONE' && <Badge text={'-'} />}
                                      {item.status === 'FILL_RATE' && <Badge type={'sky'} text={'점유율'} />}
                                      {item.remainRateModifiedDate && <Badge type={'green'} text={'잔여량'} />}
                                      {item.status === 'FILL_RATES' && <Badge type={'orange'} text={'검수완료'} />}
                                      {/* {item.status === 'INSPECT' && <Badge type={'orange'} text={'검수자확인'} />} */}
                                      {item.inspectYn && <Badge type={'red'} text={'재검토요청'} />}
                                    </BadgeArea>
                                  }
                                />
                              </CropInfoSt.CardLabelValueArea>
                            </CropInfoSt.CardFoot>
                          </CropInfoSt.Card>
                        );
                      })
                    ) : (
                      <NoCardData text={'해당 필터의 필지 데이터가 없습니다.'} />
                    )
                  }
                </CropInfoSt.CardWrap>
              </CropInfoSt.Content>
              {/* 페이지네이션 영역 */}
              {!isSingleLot ? (
                <div className="pageListWrap">
                  {/* <Pagination totalPages={remainStatus === 'NONE' ? 0 : surveyLots?.totalPages || 0} currentPage={currentPage} setCurrentPage={setCurrentPage} /> */}
                  <Pagination totalPages={surveyLots?.totalPages || 0} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </div>
              ) : null}
            </CropInfoSt.ContentWrap>
            <>
              <ActiveArea
                //   hide={!cardData.filter(({ active }) => active).length}
                hide={false}
                cardData={cardData}
                round={round}
                refetchSurveyLots={refetchSurveyLots}
                lastCheckedId={lastCheckedId}
                onChangeFeatureStatus={onChangeFeatureStatus}
                updateTime={updateTime}
              />
              <DataEnterArea
                //  hide={!cardData.filter(({ active }) => active).length}
                hide={false}
                cardData={cardData}
                round={round}
                prevSurveyCrops={prevSurveyCrops}
                surveyCrops={surveyCrops}
                surveyDatas={surveyDatas}
                refetchSurveyLots={refetchSurveyLots}
                refetchSurveyData={refetchSurveyData}
                selectNextCard={selectNextCard}
                onChangeFeatureStatus={onChangeFeatureStatus}
                isSingleLot={isSingleLot}
                updateSingleCard={updateSingleCard}
              />
            </>
            {/* )} */}
          </CropInfoSt.Container>
        </CropInfoSt.ContainerWrap>
      </CropInfoSt.Wrap>
      {/* 필지 상세 */}
      {popupState.lotInfo ? <PopupLotInfo onClose={() => onTogglePopup('lotInfo')} lotInfo={lotInfo} /> : null}
    </>
  );
}

export default CropInfoBox;

export const NoCardData = ({ text }) => {
  return (
    <>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '60px' }}>
        <p style={{ color: '#7c7c7c', fontWeight: '500', fontSize: '14px' }}>{text}</p>
      </div>
    </>
  );
};

const ImagePreloader = ({ srcArray }) => {
  return (
    <div style={{ display: 'none' }}>
      {srcArray.map((src, index) => (
        <img key={index} src={src} />
      ))}
    </div>
  );
};
