import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Input from './Input';
import { LabelInputProps, LabelInputStyle } from './LabelInput';
import Select, { SelectProps } from './Select';

export interface LabelSelectProps extends LabelInputProps, SelectProps {
  /** Select의 옵션리스트*/
  option: { text: string; value: string | number; active: boolean }[];
  /** Select 테두리의 색상값*/
  $borderColor?: string;
  /** Select 우측에 보조텍스트가 있을 경우 입력*/
  subText?: string;
}

/** Label+Select 조합의 컴포넌트*/
const LabelSelect = ({ $labelWidth, fontSize, marginbottom, $alignItem, label, value, padding, border, inputWidth, inputHeight, required, placeholder, onChange, option, $borderColor, subText, name, disabled }: LabelSelectProps) => {
  return (
    <LabelInputStyle.Wrap marginbottom={marginbottom} $alignItem={$alignItem} border={border} padding={padding}>
      <LabelInputStyle.Key $labelWidth={$labelWidth} fontSize={fontSize} required={required}>
        {label}
      </LabelInputStyle.Key>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Select width={inputWidth} height={inputHeight} value={value} option={option} name={name} onChange={onChange} placeHolder={placeholder} $borderColor={$borderColor} disabled={disabled} />
        {subText ? <LabelInputStyle.SubText>{subText}</LabelInputStyle.SubText> : null}
      </div>
    </LabelInputStyle.Wrap>
  );
};

export default LabelSelect;
