import React, { useEffect, useState } from 'react';
import exifr from 'exifr';
import styled from 'styled-components';
import axios from 'axios';

//HACK 임시로 이미지의 메타데이터를 전송하는 컴포넌트. 추후 삭제예정
const OpenImage = () => {
  const onChange = (e) => {
    const newArr: any[] = [];
    const files = [...e.target.files];
    let int = 0;
    const prom = files.map(async (file) => {
      await exifr
        .parse(file, { xmp: true })
        .then((i) => {
          int = int + 1;
          console.log(i);
          let lat = i.GPSLatitude[0] + '° ' + i.GPSLatitude[1] + "' " + Number(i.GPSLatitude[2].toFixed(2)) + '"';
          let lon = i.GPSLongitude[0] + '° ' + i.GPSLongitude[1] + "' " + Number(i.GPSLongitude[2].toFixed(2)) + '"';
          console.log('성공:' + file.name);
          if (i.latitude && i.longitude) {
            newArr.push({
              droneLRFTargetAbsAlt: i.LRFTargetAbsAlt,
              // droneRelativeAltitude: i.RelativeAltitude,
              gpsLongitude: lon,
              gpsLatitude: lat,
              // fileName: file.name,
            });
          }
        })
        .catch((err) => {
          console.log('실패:' + file.name);
        })
        .finally(() => {
          console.log('total:', int);
        });
    });
    Promise.all(prom).then(() => {
      axios
        .patch('/farm-ez/api/survey/image/altitude', newArr, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .finally(() => {
          console.log('호출종료');
          e.target.value = '';
        });
    });
  };
  return (
    <>
      <label htmlFor={'fileUpload2'} style={{ background: '#068764', cursor: 'pointer', display: 'flex', alignItems: 'center', width: 5, height: 5, zIndex: 10, position: 'fixed', left: 0, top: 0 }}></label>
      <input
        id={'fileUpload2'}
        type={'file'}
        multiple={true}
        // @ts-ignore
        webkitdirectory=""
        directory=""
        onChange={onChange}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default OpenImage;
