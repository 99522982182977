import React, { useEffect, useState } from 'react';
import IcoPage4 from '../../../assets/images/nav/ico-menu4-page.svg';
import IcoNoProject from '../../../assets/images/noData/ico-no-project.svg';
import PagingTable, { PagingTableStyle } from '../../../components/atoms/PagingTable';
import AdminContainer from '../../../components/templates/Container/AdminContainer';
import Checkbox from '../../../components/atoms/Checkbox';
import RoundBadge from '../../../components/atoms/RoundBadge';
import TdEllipsis from '../../../components/atoms/TdEllipsis';
import NoData from '../../../components/atoms/NoData';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../../components/molcures/SearchInput';
import { useRecoilState } from 'recoil';
import { confirmState } from '../../../store/Atoms';
import PopupSignUpInspector from '../../../popup/PopupSignUpInspector';
import query from 'hooks/RQuery/utils';
import IcoAddUser from '../../../assets/images/noData/ico-user-add.svg';

const InspectorList = () => {
  const navigate = useNavigate();
  const companyId = sessionStorage.getItem('companyId');
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [isAllCheck, setIsAllCheck] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  //필터
  const [condition, setCondition] = useState('');

  //테이블 데이터
  const list = query.member.useGetMembers({ type: 'inspector', companyId: companyId, pageNum: currentPage, size: 10, condition: condition, inspectorCompanyId: null });
  const [tableData, setTableData] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [checkSingle, setCheckSingle] = useState<string[]>([]);

  //선택항목의 memberId들
  const removeInspector = query.member.useRemoveMember({ memberIds: checkList });

  //단일삭제 memberId
  const checkRemoveInspector = query.member.useRemoveMember({ memberIds: checkSingle });

  //팝업 상태관리
  const [popupState, setPopState] = useState({
    signUp: false,
  });

  //팝업 토글
  const onTogglePopup = (key) => {
    setPopState({ ...popupState, [key]: !popupState[key] });
  };

  //전체 선택/해제
  const onCheckAll = () => {
    const newArr: any = [...tableData];
    if (newArr.length === newArr.filter(({ checked }) => checked).length) {
      newArr.map((i) => {
        i.checked = false;
      });
    } else {
      newArr.map((i) => {
        i.checked = true;
      });
    }
    setTableData(newArr);
  };

  //체크박스 클릭
  const onCheck = (id) => {
    const newArr: any = [...tableData];
    newArr.find((i) => i.memberId === id)!.checked = !newArr.find((i) => i.memberId === id)?.checked;
    setTableData(newArr);
  };

  //선택항목 삭제
  const onRemove = () => {
    setConfirm({
      ...confirm,
      show: true,
      message: '선택한 검수자를 삭제하시겠습니까?',
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: () => {
        removeInspector.mutate(
          { memberIds: checkList },
          {
            onSuccess: (res: any) => {
              setConfirm({ ...confirm, leftText: undefined, show: true, rightFunc: () => setConfirm({ ...confirm, show: false }), message: res?.message });
            },
            onError: (err) => {
              setConfirm({ ...confirm, leftText: undefined, show: true, rightFunc: () => setConfirm({ ...confirm, show: false }), message: (err?.response as any)?.data?.message });
            },
          },
        );
      },
    });
  };

  //행 삭제 버튼 삭제
  const onRemoveSingle = (id) => {
    setCheckSingle([id]);
    setConfirm({
      ...confirm,
      show: true,
      message: '선택한 검수자를 삭제하시겠습니까?',
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: () => {
        checkRemoveInspector.mutate(
          {
            memberIds: checkSingle,
          },
          {
            onSuccess: (res: any) => {
              setConfirm({ ...confirm, leftText: undefined, show: true, rightFunc: () => setConfirm({ ...confirm, show: false }), message: res?.message });
            },
            onError: (err) => {
              setConfirm({ ...confirm, leftText: undefined, show: true, rightFunc: () => setConfirm({ ...confirm, show: false }), message: (err?.response as any)?.data?.message });
            },
          },
        );
      },
    });
  };

  //상세페이지 이동
  const onNavigateDetail = (i) => {
    navigate('/inspector/detail', {
      state: {
        info: i,
      },
    });
  };

  //체크된수 === 전체 수 같은지 확인 후 전체체크박스 체크선택/해제
  useEffect(() => {
    if (tableData.length !== 0 && tableData.filter(({ checked }) => checked).length === tableData.length) {
      setIsAllCheck(true);
    } else {
      setIsAllCheck(false);
    }
    const newArr: any = [];
    tableData
      .filter(({ checked }) => checked)
      .map((i: any) => {
        newArr.push(i.memberId);
      });
    setCheckList(newArr);
  }, [tableData.filter(({ checked }) => checked).length]);

  useEffect(() => {
    if (list.data) {
      const newArr: any = [];
      list.data.content.map((i) => {
        newArr.push({ ...i, checked: false });
      });
      setTableData(newArr);
      setTotalPages(list.data.totalPages);
    }
  }, [list.data]);

  return (
    <AdminContainer
      title={'검수자 관리'}
      icon={IcoPage4}
      headRight={
        <button type={'button'} style={{ color: '#00B074', fontSize: 16, fontWeight: 500 }} onClick={() => onTogglePopup('signUp')}>
          + 검수자 추가
        </button>
      }
      content={
        <>
          <SearchInput
            onChange={(e) => setCondition(e.target.value)}
            onClick={() => {
              if (currentPage === 1) {
                list.refetch();
              } else {
                setCurrentPage(1);
              }
            }}
            inputWidth={320}
            height={48}
            placeholder={'이름 또는 E-mail로 검색'}
          />
          <PagingTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            caption={'검수자 목록'}
            colgroup={[80, 350, 262, 'auto', 70]}
            totalPages={totalPages}
            thead={
              <tr>
                <th scope={'col'}>
                  <Checkbox id={'inspectorListAll'} onChange={onCheckAll} checked={isAllCheck} />
                </th>
                <th scope={'col'}>이름</th>
                <th scope={'col'}>이메일</th>
                <th scope={'col'}>발주처</th>
                <th scope={'col'}></th>
              </tr>
            }
            tbody={
              <>
                {tableData.length ? (
                  tableData.map((i: any) => {
                    return (
                      <tr key={i.memberId} className={i.checked ? 'checked' : undefined}>
                        <td>
                          <Checkbox id={'inspectorList_' + i.memberId} checked={i.checked} onChange={() => onCheck(i.memberId)} />
                        </td>
                        <td onClick={() => onNavigateDetail(i)}>{i.name}</td>
                        <td onClick={() => onNavigateDetail(i)}>{i.email}</td>
                        <td onClick={() => onNavigateDetail(i)}>{i.companyName}</td>
                        <td>
                          <PagingTableStyle.BtnRowRemove className={`btnRemove ${i.checked ? 'checked' : undefined}`} type={'button'} onClick={() => onRemoveSingle(i.memberId)}></PagingTableStyle.BtnRowRemove>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="noHover">
                    <td colSpan={5} style={{ height: 540 }}>
                      <NoData text={'검수자를 추가하세요'} icon={IcoAddUser} $iconSize={54} color={'#3F3F3F'} />
                    </td>
                  </tr>
                )}
              </>
            }
            $hasTotal={true}
            checkLength={tableData.filter(({ checked }) => checked).length}
            $totalBtnName={'삭제'}
            onRemove={onRemove}
          />
          {popupState.signUp ? <PopupSignUpInspector onClose={() => onTogglePopup('signUp')} /> : null}
        </>
      }
    />
  );
};

export default InspectorList;
