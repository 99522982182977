import * as apiUrl from './apiUrl';
import { request } from '../../../service/farmEzCommon';
import qs from 'qs';

//관리자 - 사업회차 작물코드 조회
export const getSurveyCrop = async (surveyId) => {
  // 기타 항목 정렬 (예: first 입력시 위로 올라옴)
  const sort = '';
  const { data } = await request.get(apiUrl.survey.getCrops(surveyId), { params: sort && { sort } });
  return data.datas;
};

//관리자 - 사업회차 작물코드 추가
export const addSurveyCrop = async ({ surveyId, saveSurveyCrops }) => {
  const { data } = await request.post(apiUrl.survey.addCrops(surveyId), { saveSurveyCrops });
  return data;
};

//관리자 - 사업회차 작물코드 삭제
export const deleteSurveyCrop = async ({ deleteSurveyCropIds }) => {
  console.log('api', deleteSurveyCropIds);
  const { data } = await request.delete(apiUrl.survey.deleteCrops, { data: { deleteSurveyCropIds } });
  return data;
};

//관리자 - 사업회차 작물코드 명칭 수정
export const updateSurveyCrop = async ({ updateSurveyCrops }) => {
  const { data } = await request.patch(apiUrl.survey.updateCrops, { updateSurveyCrops });
  return data;
};

//관리자 - 사업회차 작물코드 레벨 변경
export const updateCropLevel = async ({ updateSurveyCrops }) => {
  const { data } = await request.patch(apiUrl.survey.updateCropLevel, { updateSurveyCrops });
  return data.datas;
};

//관리자 - 사업회차 필지 추가 조건 목록
export const getSurveyConditions = async (surveyId) => {
  const { data } = await request.get(apiUrl.survey.getCondition(surveyId));
  return data.datas;
};

//관리자 - 사업회차 필지 추가 조건 추가
export const addSurveyConditions = async ({ surveyId, list }) => {
  const { data } = await request.post(apiUrl.survey.addCondition(surveyId), {
    saveSurveyConditions: list,
  });
  return data;
};

//관리자 - 사업회차 필지 추가 조건 삭제
export const removeSurveyConditions = async ({ surveyId, list }) => {
  const newArr: any = [];
  list.map((i) => {
    newArr.push(i.surveyConditionId);
  });
  const { data } = await request.delete(apiUrl.survey.removeCondition(surveyId), {
    data: {
      deleteSurveyConditionIds: newArr,
    },
  });
  return data;
};

//지도 데이터 호출
export const getMapData = async ({ surveyId, stdgCd }) => {
  const { data } = await request.get(apiUrl.survey.getMap(surveyId), {
    params: {
      stdgCd,
    },
  });
  return data;
};

// 회차별 중복 이미지 목록 조회
export const getDuplicatedImages = async (surveyId) => {
  const { data } = await request.get(apiUrl.survey.duplicatedImage(surveyId));
  return data.datas;
};

// 회차별 이미지 상세보기
export const getDuplicatedImagesDetail = async (surveyFarmMapId) => {
  const { data } = await request.get(apiUrl.survey.duplicatedImageDetail(surveyFarmMapId));
  return data.datas;
};

//관리자 - 사업회차 상태 변경 (예 : READY -> )
export const updateSurveyStatus = async ({ surveyId, surveyStatus }) => {
  const { data } = await request.patch(apiUrl.survey.updateSurveyStatus(surveyId), {
    surveyStatus,
  });
  return data;
};

//회차별 필지 조회 - 목록(paging)
export const getLots = async ({ surveyId, stdgCd, clsfNms, surveyCropIds, status, remainStatus, inspectStatus, pageNum, size }) => {
  const removeNullValues = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== null));
  };
  const params = removeNullValues({ surveyId, stdgCd, clsfNms, surveyCropIds, status, remainStatus, inspectStatus, pageNum, size });

  const { data } = await request.get(apiUrl.survey.getLots(surveyId), {
    params: params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
      // 배열을 'repeat' 형식으로 직렬화하여 clsfNms=논&clsfNms=밭&... 식으로 전달
    },
  });
  return data.datas;
};

//회차별 필지 조회 - 단일
export const getSingleLot = async ({ surveyId, surveyFarmMapId }) => {
  const { data } = await request.get(apiUrl.survey.updateSingleSurveyStatus(surveyId, surveyFarmMapId));
  return data.datas;
};

// 회차별 필지 판독 [점유율] 저장
export const addFillRate = async ({ surveyFarmMapId, saveSurveyCropFillRates, workerName }) => {
  const { data } = await request.post(apiUrl.survey.addFillRate(surveyFarmMapId), {
    saveSurveyCropFillRates,
    workerName,
  });
  return data;
};
// 회차별 필지 판독 [점유율] [일괄] 저장 - 미판독 상태 필지들
export const addFillRateMulti = async ({ surveyFarmMapIds, surveyCropFillRateRequest }) => {
  const { data } = await request.post(apiUrl.survey.addFillRateMulti, {
    surveyFarmMapIds,
    surveyCropFillRateRequest,
  });
  return data;
};

// ! API 나오면 url 수정필요 : 회차별 필지 판독 [점유율] [일괄] 저장 - 미판독이 아닌 필지들(이전값을 그대로 저장하는 API)
export const addFillRateMulti_PrevData = async ({ surveyFarmMapIds, workerName }) => {
  const { data } = await request.post(apiUrl.survey.addFillRateMultiPrev, {
    surveyFarmMapIds,
    workerName,
  });
  return data;
};

// 회차별 필지 판독 잔여량 수정 (점유율 저장이 최초 저장임, 이후는 수정)
export const updateRemain = async ({ surveyFarmMapId, updateSurveyCropRemainRates, workerName }) => {
  const { data } = await request.patch(apiUrl.survey.updateRemain(surveyFarmMapId), { updateSurveyCropRemainRates, workerName });
  return data;
};

// 회차별 필지 판독 정보 조회
export const getSurveyData = async ({ surveyFarmMapId }) => {
  const { data } = await request.get(apiUrl.survey.getSurveyData(surveyFarmMapId));
  return data.datas;
};

//회차별 이미지 업로드 현황 조회
export const getUploadImages = async ({ surveyId }) => {
  const { data } = await request.get(apiUrl.survey.uploadImageList(surveyId), {
    params: {},
  });
  return data;
};

// 회차별 중복 이미지 선택 등록(선택 외 나머지 삭제)
export const deleteDuplicateImage = async ({ surveyId, surveyFarmMapId, wideImageId, zoomImageId }) => {
  const { data } = await request.delete(apiUrl.survey.deleteDuplicateImage(surveyId), { data: { surveyFarmMapId, wideImageId, zoomImageId } });
  return data;
};

//회차별 필지 검수완료 -> 수정)확인완료 -> 12.23수정)재검토요청
export const completeInspect = async (surveyFarmMapId) => {
  const { data } = await request.patch(apiUrl.survey.inspect(surveyFarmMapId), {});
  return data;
};

//회차별 차트
export const getChart = async (surveyId) => {
  const { data } = await request.get(apiUrl.survey.getChart(surveyId));
  return data.datas;
};

//누락필지 shp 다운로드
export const getImageShp = async ({ surveyId, stdgCds }) => {
  const { data } = await request.get(apiUrl.survey.getImageShp(surveyId), {
    params: { stdgCds },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
      // 배열을 'repeat' 형식으로 직렬화하여 stdgCds=1234&stdgCds=2233&... 식으로 전달
    },
  });
  return data.datas;
};

//회차별 법정동 주소 리스트
export const getStdg = async ({ surveyId }) => {
  const { data } = await request.get(apiUrl.survey.getStdg(surveyId));
  return data.datas;
};
