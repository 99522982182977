import React from 'react';
import styled from 'styled-components';

export const NoDataStyle = {
  Wrap: styled.div<NoDataProps>`
    padding: ${(p) => (p.padding?.length ? p.padding[0] + 'px 0 ' + p.padding[1] + 'px' : '79px 0')};
  `,
  Icon: styled.div<NoDataProps>`
    width: ${(p) => (p.$iconSize ? p.$iconSize + 'px' : '48px')};
    height: ${(p) => (p.$iconSize ? p.$iconSize + 'px' : '48px')};
    background: url(${(p) => (p.icon ? p.icon : '')}) no-repeat center;
    margin: 0 auto 8px;
  `,
  Text: styled.p<NoDataProps>`
    color: ${(p) => (p.color ? p.color : '#6f979c')};
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-align: center;
  `,
};

export interface NoDataProps {
  /** 상하여백(배열 첫번째값:위, 배열 두번째값:아래)*/
  padding?: number[];
  /** 텍스트 크기*/
  fontSize?: number;
  /** 이미지의 크기*/
  $iconSize?: number;
  icon?: string;
  text?: string;
  color?: string;
}

/** 테이블/리스트 등 데이터가 없을 때 보여지는 컴포넌트*/
const NoData = ({ padding, text, fontSize, icon, $iconSize, color }: NoDataProps) => {
  return (
    <NoDataStyle.Wrap padding={padding}>
      <NoDataStyle.Icon $iconSize={$iconSize} icon={icon}></NoDataStyle.Icon>
      <NoDataStyle.Text fontSize={fontSize} dangerouslySetInnerHTML={{ __html: String(text) }} color={color}></NoDataStyle.Text>
    </NoDataStyle.Wrap>
  );
};

export default NoData;
