import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { confirmState } from '../../store/Atoms';
import Button from '../atoms/Button';

export const ConfirmStyle = {
  Dimmed: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.5);
  `,
  Wrap: styled.div<ConfirmProps>`
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 1002;
    transform: translate(-50%, -50%);
    background: #fff;
    width: ${(p) => (p.width ? p.width + 'px' : '280px')};
    border-radius: 4px;
  `,
  TextArea: styled.div`
    padding: 40px 0;
    color: #3f3f3f;
    font-weight: 500;
    text-align: center;
    white-space: pre-wrap;
  `,
  TextMain: styled.div`
    font-size: 16px;
  `,
  TextSub: styled.div`
    margin-top: 10px;
    font-size: 14px;
  `,
  ButtonArea: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 12px;
    button {
      flex: 1;
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  `,
};

export interface ConfirmProps {
  width?: number;
  message?: string;
  messageSub?: string;
  leftText?: string;
  rightText?: string;
  leftFunc?: () => void;
  rightFunc?: () => void;
  show?: boolean;
  isError?: boolean;
}

const Confirm = () => {
  const [info, setInfo] = useRecoilState(confirmState);

  useEffect(() => {
    const onEsc = (e) => {
      if (info.leftText === undefined) {
        if (e.key === 'Escape') {
          setInfo({ ...info, show: false });
        }
      }
    };
    window.addEventListener('keyup', onEsc);
    return () => window.removeEventListener('keyup', onEsc);
  });
  return (
    <>
      {info.show ? (
        <>
          <ConfirmStyle.Dimmed></ConfirmStyle.Dimmed>
          <ConfirmStyle.Wrap width={info.width}>
            <ConfirmStyle.TextArea>
              <ConfirmStyle.TextMain dangerouslySetInnerHTML={{ __html: String(info.message) }} />
              {info.messageSub && <ConfirmStyle.TextSub dangerouslySetInnerHTML={{ __html: String(info.messageSub) }} />}
            </ConfirmStyle.TextArea>

            <ConfirmStyle.ButtonArea>
              {info.leftText ? <Button $colorType={'white'} height={48} text={info.leftText} onClick={info.leftFunc} /> : null}
              {info.rightText ? <Button $buttonType={!info.isError ? 'large' : undefined} $colorType={info.isError ? 'orange' : undefined} height={48} text={info.rightText} onClick={info.rightFunc} /> : null}
            </ConfirmStyle.ButtonArea>
          </ConfirmStyle.Wrap>
        </>
      ) : null}
    </>
  );
};

export default Confirm;
