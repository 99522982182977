import React, { useEffect, useState } from 'react';
import IcoHelp from 'assets/images/etc/ico-help-message.svg';
import FileButton from 'components/atoms/FileButton';
import Checkbox from 'components/atoms/Checkbox';
import ScrollTable from 'components/atoms/ScrollTable';
import { useRecoilState } from 'recoil';
import { confirmState } from 'store/Atoms';
import Button from 'components/atoms/Button';
import IcoStepNext from 'assets/images/button/ico-step-next.svg';
import { NextStep, Section } from 'components/atoms/TabContent';
import HelpMessage from 'components/atoms/HelpMessage';
import NoData from 'components/atoms/NoData';
import IcoStepDone from 'assets/images/button/ico-step-done.svg';
import IcoNoCrop from 'assets/images/noData/ico-no-crop.svg';
import IcoFileUpload from 'assets/images/button/ico-file-upload.svg';
import PopupAddCrop from 'popup/PopupAddCrop';
import query from 'hooks/RQuery/utils';
import { projectDetailState, ProjectDetailProps } from 'store/Atoms';
import styled from 'styled-components';
import { getFirstDecimal } from 'util/global';
import IcoStepDoneDisabled from '../../../../../assets/images/button/ico-step-done-disabled.svg';

const TableSt = {
  Input: styled.input`
    &:disabled {
      background-color: #f1f1f5;
    }
    &.shadow {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0.5%, #f1f1f5 5.5%, #f1f1f5);
    }
  `,
};

// 작물정보 탭
const RoundStep2 = ({ surveyId }) => {
  const [projectDetail, setProjectDetail] = useRecoilState<ProjectDetailProps>(projectDetailState); // 선택된 사업 상세 정보
  const [openPopCrop, setOpenPopCrop] = useState(false); // 직물정보 불러오기 팝업
  const [confirm, setConfirm] = useRecoilState(confirmState); // 알람창
  const [colList, setColList] = useState<any>(['id', 'CODE1', 'CODE2', 'CODE3', 'NM1', 'NM2', 'NM3', 'DISPLAY_NM']);
  const [isAllCheck, setIsAllCheck] = useState(false); // 전체 선택 유무

  const [oldCodeData, setOldCodeData] = useState<any>([]); // 기존 데이터
  const [codeData, setCodeData] = useState<any>([
    // { NM1: '', CODE1: '', NM2: '', CODE2: '', NM3: '', CODE3: '', DISPLAY_NM:'', checked: false }
  ]); // 보여지는 최종 데이터

  //관리자 - 사업 회차별 작물코드 조회
  const { data: surveyCropList } = query.survey.useGetCrops(surveyId);
  //관리자 - 사업회차 작물코드 추가
  const { mutate: addSurveyCrops } = query.survey.useAddCrops({ surveyId });
  //관리자 - 사업회차 작물코드 삭제
  const { mutate: deleteSurveyCrops } = query.survey.useDeleteCrops({ surveyId });
  //관리자 - 사업 회차 작물코드 명칭 수정
  const { mutate: updateSurveyCrops } = query.survey.useUpdateCrops({ surveyId });

  // 기존 데이터 저장 (oldData)
  useEffect(() => {
    if (surveyCropList?.length > 0) {
      // 시설, 휴경지 등은 거르기
      let validArr = surveyCropList.filter((ele, idx) => getFirstDecimal(ele?.level) === 0);
      setOldCodeData(
        validArr?.map((ele, idx) => ({
          inspectorCropId: ele.inspectorCropId,
          surveyCropId: ele.surveyCropId,
          CODE1: ele.code1,
          CODE2: ele.code2,
          CODE3: ele.code3,
          NM1: ele.name1,
          NM2: ele.name2,
          NM3: ele.name3,
          DISPLAY_NM: ele.displayName,
          checked: false,
        })),
      );
    } else {
      setOldCodeData([]);
    }
  }, [surveyCropList]);

  // check용으로 만들기 (newData)
  useEffect(() => {
    if (surveyCropList?.length > 0) {
      // 시설, 휴경지 등은 거르기
      let validArr = surveyCropList.filter((ele, idx) => getFirstDecimal(ele?.level) === 0);
      setCodeData(
        validArr?.map((ele, idx) => ({
          inspectorCropId: ele.inspectorCropId,
          surveyCropId: ele.surveyCropId,
          CODE1: ele.code1,
          CODE2: ele.code2,
          CODE3: ele.code3,
          NM1: ele.name1,
          NM2: ele.name2,
          NM3: ele.name3,
          DISPLAY_NM: ele.displayName,
          checked: false,
          isDuplicated: false,
          isInvalid: !ele.displayName,
        })),
      );
    } else {
      setCodeData([]);
    }
  }, [surveyCropList]);

  //전체 선택/해제
  const onCheckAll = (e) => {
    const { checked } = e.target;
    setCodeData((prev) => [...prev]?.map((ele) => ({ ...ele, checked })));
  };

  //체크박스 클릭
  const onCheck = (e, idx) => {
    const { id, checked } = e.target;
    console.log(id, idx);
    setCodeData((prev) => [...prev]?.map((ele, index) => (`code_${index}` === id ? { ...ele, checked } : ele)));
  };

  // 체크된수 === 전체 수 같은지 확인 후 전체체크박스 체크선택/해제
  useEffect(() => {
    const isAllCheck = codeData?.length > 0 && codeData.filter(({ checked }) => checked).length === codeData?.length;
    if (isAllCheck) {
      setIsAllCheck(true);
    } else {
      setIsAllCheck(false);
    }
  }, [codeData]);

  //행 추가 (없애기? 복제로 대체하기?)
  const onRowAdd = () => {
    let obj = {};
    colList.map((key) => {
      obj = { ...obj, [key]: '', checked: false };
    });

    setCodeData((prev) => [...prev, obj]);
  };

  // 복제 버튼 클릭
  const onClickCopyRow = () => {
    let copyList = codeData?.filter(({ checked }) => checked);
    setCodeData((prev) => {
      let newCopyList = copyList?.map((ele, idx) => ({ ...ele, checked: false }));
      return [...prev, ...newCopyList];
    });
  };

  //테이블 내 데이터 변경값 state 변경
  const onChangeValue = (e, key, num) => {
    const val = e.target.value;
    const newArr = [...codeData];
    newArr.filter((i, index) => index === num)[0][key] = val;
    setCodeData(newArr);
  };

  //선택 행 삭제
  const onRemoveCheck = () => {
    setConfirm({
      ...confirm,
      show: true,
      leftText: '취소',
      rightText: '확인',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: () => {
        const newArr = [...codeData];
        setCodeData(newArr.filter(({ checked }) => !checked));
        setConfirm({ ...confirm, show: false });
      },
      message: '선택한 항목을 삭제하시겠습니까?',
      isError: true,
    });
  };

  // 저장버튼 (최종 데이터 분류하기)
  const onClickSave = async () => {
    console.log(oldCodeData, codeData);

    // 빈 항목 찾기(DISPLAY_NM 값 필수)
    const invalid = codeData.filter(({ DISPLAY_NM }) => !DISPLAY_NM);
    if (invalid?.length > 0) {
      setCodeData((prev) => [...prev].map((ele) => ({ ...ele, isInvalid: !ele.DISPLAY_NM })));
      setConfirm({
        ...confirm,
        show: true,
        leftText: undefined,
        rightText: '확인',
        rightFunc: () => setConfirm({ ...confirm, show: false }),
        message: '별칭이 빈 항목이 있습니다.',
        isError: true,
      });
      return; // (요청 막기)
    }

    // 중복된 항목 찾기
    const duplicates = codeData.filter((item, index) => codeData.some((otherItem, otherIndex) => otherIndex !== index && item.surveyCropId === otherItem.surveyCropId && item.DISPLAY_NM === otherItem.DISPLAY_NM));
    if (duplicates?.length > 0) {
      // error 표시용
      setCodeData((prev) => {
        return [...prev].map((ele) => {
          const isDuplicated = duplicates.filter(({ surveyCropId, DISPLAY_NM }) => ele.surveyCropId === surveyCropId && ele.DISPLAY_NM === DISPLAY_NM)?.length > 1;
          return { ...ele, isDuplicated };
        });
      });
      setConfirm({
        ...confirm,
        show: true,
        leftText: undefined,
        rightText: '확인',
        rightFunc: () => setConfirm({ ...confirm, show: false }),
        message: '별칭이 중복된 항목이 있습니다.',
        isError: true,
      });
      return; // (요청 막기)
    }
    // 저장 전, 확인 팝업
    setConfirm({
      ...confirm,
      show: true,
      leftText: '취소',
      rightText: '확인',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: async () => {
        await updatedSave()
          .then((res) => {
            setConfirm({ ...confirm, show: true, leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }), message: '저장이 완료되었습니다.' });
          })
          .catch(() => {
            setConfirm({ ...confirm, show: true, leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }), message: '실패. 관리자에게 문의하세요.' });
          });
      },
      message: '변경된 사항을 저장하시겠습니까?',
    });

    // 변경된 사항을 저장
    async function updatedSave() {
      // 추가된 항목 찾기 (다른 surveyCropId)
      const addItems = codeData.filter((newItem) => {
        return !oldCodeData.some((oldItem) => oldItem.surveyCropId === newItem.surveyCropId);
      });
      console.log('add', addItems);

      // 추가 서버 요청
      if (addItems?.length > 0) {
        const addArr = [...addItems]?.map((ele) => ({ inspectorCropId: ele.inspectorCropId, displayName: ele?.DISPLAY_NM }));

        await addSurveyCrops(
          { surveyId, saveSurveyCrops: addArr },
          {
            onSuccess(data, variables, context) {
              console.log(data);
            },
            onError(error, variables, context) {
              console.log(error);
            },
          },
        );
      }

      // 수정된 항목 찾기 ( surveyCropId는 같고, displayName이 다른것)
      const updatedItems = codeData.filter((newItem) => {
        return oldCodeData.find((oldItem) => oldItem.surveyCropId === newItem.surveyCropId && oldItem.DISPLAY_NM !== newItem.DISPLAY_NM);
      });
      console.log('update', updatedItems);

      if (updatedItems?.length > 0) {
        const updateArr = [...updatedItems]?.map((ele) => ({ surveyCropId: ele.surveyCropId, displayName: ele?.DISPLAY_NM }));
        await updateSurveyCrops(
          { updateSurveyCrops: updateArr },
          {
            onSuccess(data, variables, context) {
              console.log(data);
            },
            onError(error, variables, context) {
              console.log(error);
            },
          },
        );
      }

      // 삭제된 항목 찾기 (기존에 있던 surveyCropId가 아예 없을때)
      const removedItems = oldCodeData.filter((oldItem) => !codeData.some((newItem) => newItem.surveyCropId === oldItem.surveyCropId));
      console.log('removedItems', removedItems);
      // 삭제 서버 요청
      if (removedItems?.length > 0) {
        let deleteArr = [...removedItems]?.map((ele) => ele.surveyCropId);

        await deleteSurveyCrops(
          { deleteSurveyCropIds: deleteArr },
          {
            onSuccess(data, variables, context) {
              console.log(data);
            },
            onError(error, variables, context) {
              console.log(error);
            },
          },
        );
      }
    }
  };

  return (
    <>
      <Section style={{ width: 1272, height: 'calc(100% - 82px - 39px - 52px - 60px)' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 16 }}>
          <HelpMessage message={'발주처의 등록된 작물 분류를 불러올 수 있습니다.'} icon={IcoHelp} />
          <Button $buttonType={'medium'} text={'작물 정보 불러오기'} icon={IcoFileUpload} $iconSize={20} $iconSpacing={6} onClick={() => setOpenPopCrop(true)} />
        </div>
        <ScrollTable
          $hasBorder={true}
          $minHeight={'100%'}
          $maxHeight={'100%'}
          caption={'필지 정보'}
          colgroup={['auto', 235, 160, 235, 160, 235, 160, 160]}
          thead={
            <tr>
              <th scope={'col'}>
                <Checkbox onChange={onCheckAll} id={'checkAll'} checked={isAllCheck} />
              </th>
              <th scope={'col'}>대분류</th>
              <th scope={'col'}>대분류 코드</th>
              <th scope={'col'} className={'shadow'}>
                중분류
              </th>
              <th scope={'col'}>중분류 코드</th>
              <th scope={'col'} className={'shadow'}>
                작물명
              </th>
              <th scope={'col'}>작물 코드</th>
              <th scope={'col'}>별칭</th>
            </tr>
          }
          tbody={
            <>
              {codeData?.length > 0 ? (
                <>
                  {codeData.map((i: any, idx: number) => {
                    return (
                      <tr key={idx} className={i.checked ? 'checked' : undefined}>
                        <td>
                          <Checkbox id={'code_' + idx} checked={i.checked} onChange={(e) => onCheck(e, idx)} />
                        </td>
                        <td>
                          <TableSt.Input type={'text'} value={i.NM1} onChange={(e) => onChangeValue(e, 'NM1', idx)} placeholder={'입력'} disabled />
                        </td>
                        <td>
                          <TableSt.Input type={'text'} value={i.CODE1} onChange={(e) => onChangeValue(e, 'CODE1', idx)} placeholder={'입력'} disabled />
                        </td>
                        <td className={'shadow'}>
                          <TableSt.Input className={'shadow'} type={'text'} value={i.NM2} onChange={(e) => onChangeValue(e, 'NM2', idx)} placeholder={'입력'} disabled />
                        </td>
                        <td>
                          <TableSt.Input type={'text'} value={i.CODE2} onChange={(e) => onChangeValue(e, 'CODE2', idx)} placeholder={'입력'} disabled />
                        </td>
                        <td className={'shadow'}>
                          <TableSt.Input className={'shadow'} type={'text'} value={i.NM3} onChange={(e) => onChangeValue(e, 'NM3', idx)} placeholder={'입력'} disabled />
                        </td>
                        <td>
                          <TableSt.Input type={'text'} value={i.CODE3} onChange={(e) => onChangeValue(e, 'CODE3', idx)} placeholder={'입력'} disabled />
                        </td>
                        <td style={{ background: i.isDuplicated || i.isInvalid ? 'red' : '#fff' }}>
                          <TableSt.Input type={'text'} value={i.DISPLAY_NM} onChange={(e) => onChangeValue(e, 'DISPLAY_NM', idx)} placeholder={'입력'} />
                        </td>
                      </tr>
                    );
                  })}
                  {/* <tr className={'noHover'}>
                    <td colSpan={8}>
                      <button type={'button'} className={'rowAdd'} onClick={onRowAdd}>
                        + 추가하기
                      </button>
                    </td>
                  </tr> */}
                </>
              ) : (
                <tr className={'noHover'}>
                  <td colSpan={8} rowSpan={10} style={{ height: '100%' }}>
                    <NoData text={'작물 정보를 추가하세요.'} icon={IcoNoCrop} color={'#3F3F3F'} />
                  </td>
                </tr>
              )}
            </>
          }
          $hasTotal={codeData?.length > 0}
          $totalBtnName={'삭제'}
          checkLength={codeData.filter(({ checked }) => checked).length}
          onRemove={onRemoveCheck}
        />

        <NextStep>
          <Button $buttonType={'large'} text={'저장하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={onClickSave} $iconDisabled={IcoStepDoneDisabled} />
        </NextStep>
      </Section>

      {/* 작물정보 추가 팝업 */}
      {openPopCrop && <PopupAddCrop onClose={() => setOpenPopCrop(false)} inspectorCompanyId={projectDetail?.inspectorCompanyId} inspectorCompanyName={projectDetail?.inspectorCompanyName} setCodeData={setCodeData} />}
    </>
  );
};

export default RoundStep2;
