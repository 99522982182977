import React, { ReactElement, ReactNode } from 'react';
// import { SwiperStyle } from './_style';
import { Swiper, SwiperSlide } from 'swiper/react';

//
import 'swiper/css';
import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import 'swiper/css/thumbs';

import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';

import styled from 'styled-components';

import IcoNaviPrev from 'assets/images/popupLarge/ico-nav-prev.svg';
import IcoNaviPrevA from 'assets/images/popupLarge/ico-nav-prev-active.svg';

export const SwiperStyle = {
  Wrap: styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    .swiper {
      height: 100%;
      .swiper-wrapper {
        height: 100%;
      }
    }
    .swiper-slide {
      /* border: 1px solid red;
       box-sizing: border-box; */
      display: flex;
      justify-content: center;
      /* 이미지 사이즈 */
      img {
        width: auto;
        height: 100%;
        /* border-radius: 4px; */
      }
    }
  `,
  Navi: styled.div`
    width: 100%;
    /* padding: 0 24px; */
    box-sizing: border-box;
    position: absolute;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btnSwiperPrev,
    .btnSwiperNext {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(${IcoNaviPrev});
      &.swiper-button-disabled {
        opacity: 0.5;
      }
      &:hover {
        background-image: url(${IcoNaviPrevA});
      }
    }
    .btnSwiperPrev {
      /* background-image: url(${IcoNaviPrev}); */
      &:hover {
      }
    }
    .btnSwiperNext {
      transform: rotate(180deg);
      &:hover {
      }
    }
  `,
  //   Footer: styled.div`
  //     width: 100%;
  //     margin-top: 5px;
  //     #swiper-pagination {
  //       margin: 0 auto;
  //       width: fit-content;
  //       .swiper-pagination-bullet {
  //         width: 20px;
  //         height: 4px;
  //         border-radius: 2px;
  //         background-color: #bbc4d4;
  //         &.swiper-pagination-bullet-active {
  //           background-color: #0076ff;
  //         }
  //       }
  //     }
  //   `,
};

/** Swiper (popup에서 사용중) */
const SwiperArea = ({ children, perView, perGroup, initialSlide, onChangeSlide }: any) => {
  return (
    <>
      <SwiperStyle.Wrap>
        <Swiper
          initialSlide={initialSlide} // 시작 슬라이드 번호 0 ~
          slidesPerGroup={perGroup ? perGroup : 1}
          spaceBetween={11}
          slidesPerView={perView ? perView : 1}
          allowTouchMove={false}
          onSlideChange={(swiper) => onChangeSlide(swiper)}
          //  onSwiper={(swiper) => console.log(swiper)}
          navigation={{
            enabled: true,
            nextEl: '#btnSwiperNext',
            prevEl: '#btnSwiperPrev',
          }}
          pagination={{
            el: '#swiper-pagination', // 기본 스타일 대신 사용할 요소
            type: 'fraction', // fraction, bullets ,..
          }}
          modules={[Navigation, Pagination]}
        >
          {children}
        </Swiper>
        {/* <SwiperStyle.Navi>
          <button type={'button'} id={'btnSwiperPrev'} className={'btnSwiperPrev'}></button>
          <button type={'button'} id={'btnSwiperNext'} className={'btnSwiperNext'}></button>
        </SwiperStyle.Navi> */}
        {/* <SwiperStyle.Footer>
          <div id={'swiper-pagination'}></div>
        </SwiperStyle.Footer> */}
      </SwiperStyle.Wrap>
    </>
  );
};

export default SwiperArea;
