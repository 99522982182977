import React, { ReactNode } from 'react';
import { atom } from 'recoil';
import { PopupProps } from '../popup/PopupTemplate';
import { ConfirmProps } from '../components/templates/Confirm';
import { UserInfoProps } from './_interface';
import { recoilPersist } from 'recoil-persist';
import { ToastPopProps } from 'components/templates/ToastPop';

// 새로운 저장소 객체 생성
export function newPersistAtom(key: string = 'sessionStorage') {
  // 스토리지에 저장하는 기능
  const { persistAtom } = recoilPersist({
    key: key,
    storage: sessionStorage || localStorage || undefined, // localStorage 또는 sessionStorage
  });
  return persistAtom;
}

/** 로그인 후 유저 정보 */
export const userInfoState = atom<UserInfoProps>({
  key: 'userData',
  default: {},
  effects_UNSTABLE: [newPersistAtom('userInfo')], // persist 적용
});

export const popupState = atom<PopupProps>({
  key: 'popupState',
  default: {
    isDimmed: true,
    title: '',
    width: 600,
    content: <></>,
    footer: undefined,
  },
});

export const confirmState = atom<ConfirmProps>({
  key: 'confirmState',
  default: {
    width: 280,
    message: '',
    leftText: undefined,
    rightText: '확인',
    leftFunc: () => {},
    rightFunc: () => {},
    show: false,
  },
});

export const toastPopState = atom<ToastPopProps>({
  key: 'toastPopState',
  default: {
    title: '',
    message: '',
    leftText: undefined,
    rightText: '확인',
    leftFunc: () => {},
    rightFunc: () => {},
    show: false,
  },
});

export const loadingState = atom<{ show: boolean; text?: string; isGif?: boolean; progress?: ReactNode }>({
  key: 'loadingState',
  default: {
    show: false,
    text: '',
    isGif: false,
  },
});

export const popupLargeImgState = atom<{ open?: boolean; imgArr?: any[]; mapId?: string; type?: string; startIndex?: number }>({
  key: 'popupLargeImg',
  default: {
    open: false,
    imgArr: [],
    mapId: '',
    type: '',
    startIndex: 0,
  },
});

export const formState = atom<any>({
  key: 'formState',
  default: {},
});

//사업 상세정보
export interface ProjectDetailProps {
  projectId?: string;
  projectName?: string;
  inspectorCompanyId?: string;
  inspectorCompanyName?: string;
  inProgressSurveyNo?: 0 | number;
  surveyCount?: 0 | number;
  status?: 'READY' | string;
  surveyBeginDate?: string;
  surveyEndDate?: string;
  prjId?: string;
}
// 관리자 - 사업장관리 - 선택된 사업 상세정보 (별도 API 없음)
export const projectDetailState = atom<ProjectDetailProps>({
  key: 'projectDetailState',
  default: {
    projectId: '',
    projectName: '',
    inspectorCompanyId: '',
    inspectorCompanyName: '',
    inProgressSurveyNo: 0,
    surveyCount: 0,
    status: '',
    surveyBeginDate: '',
    surveyEndDate: '',
    prjId: '',
  },
  effects_UNSTABLE: [newPersistAtom('projectDetail')], // persist 적용
});

export const stdgCdState = atom<{ code?: string; address?: string; dynamic?: boolean }>({
  key: 'stdgCdState',
  default: {
    code: '', // 법정동 코드 10자리
    address: '',
    dynamic: false,
  },
});
