import React, { ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { PagingTableStyle } from './PagingTable';
import Button from './Button';
import IcoFileRemove from 'assets/images/button/ico-file-remove.svg';
import IcoFileUpload from 'assets/images/button/ico-file-upload.svg';
import { Logger } from 'sass';
import silent = Logger.silent;
import { TableProps } from './NoneScrollTable';

export const ScrollTableStyle = {
  ...PagingTableStyle,
  Contain: styled.div`
    flex: 1;
    width: 100%;
    border: 1px solid #e7eaee;
    box-sizing: border-box;
    border-radius: 8px 8px 0 0;
  `,
  Wrap: styled.table<{ width?: number }>`
    height: 100%;
    width: ${(p) => (p.width ? p.width + 'px' : '100%')};
    //border-bottom: 1px solid #000;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
  `,
  Thead: styled.thead<{ $hasBorder?: boolean }>`
    position: sticky;
    top: 0;
    z-index: 5;
    tr {
      th {
        padding: 0 5px;
        background: #f6f6f6;
        color: #64748b;
        font-size: 14px;
        font-weight: 500;
        height: 54px;
        border-bottom: 1px solid #e7eaee;
        box-sizing: border-box;
        position: relative;
        text-align: left;
        label {
          justify-content: center;
        }
        > button {
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          color: #000;
          &:after {
            content: '';
            width: 5px;
            height: 5px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(-45deg);
            margin-top: -2px;
            margin-left: 10px;
            transition: 0.4s;
          }
          &.asc {
            &:after {
              transform: rotate(135deg);
              margin-bottom: -4px;
              margin-top: 0;
            }
          }
        }
        &:first-child {
          border-radius: 8px 0 0 0;
        }
        &:last-child {
          border-radius: 0 8px 0 0;
        }
        ${(p) =>
          p.$hasBorder &&
          `
            padding:0 23px;
            &:not(:last-child) {
              border-right: 1px solid #e7eaee;
            }
            &.shadow{background:linear-gradient(to right, rgba(0,0,0,.1) 0.5%, #f6f6f6 5.5%, #f6f6f6)}
          `}
      }
    }
  `,
  Tbody: styled.tbody<{ total?: string; $hasBorder?: boolean }>`
    tr {
      td {
        background: #fff;
        color: #001a21;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding: 0 5px;
        height: 60px;
        border-bottom: 1px solid #e7eaee;
        label {
          justify-content: center;
        }
        &:not(:last-child) {
        }
        .rowAdd {
          background: #f6f6f6;
          color: #3f3f3f;
          font-size: 14px;
          font-weight: 700;
          width: 1182px;
          height: 37px;
          border-radius: 4px;
          margin-left: 66px;
        }
      }
      &:last-child {
        td {
          /* border-bottom: 0; */
        }
      }
      &:hover,
      &.checked {
        &:not(.noHover) {
          td {
            background: #f6f6f6;

            &.shadow {
              background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0.5%, #f6f6f6 5.5%, #f6f6f6);
            }

            .btnRemove {
              display: flex;
            }
          }
        }
      }

      ${(p) =>
        p.$hasBorder &&
        `
          td{
              padding:0;
              &:not(:last-child) {
                border-right: 1px solid #e7eaee;
              }
              &.shadow{background:linear-gradient(to right, rgba(0,0,0,.1) 0.5%, #fff 5.5%, #fff)};
              input{height:100%;width:100%;padding:0 23px;margin:0;border:none;background:transparent;
                &::placeholder{color:#A5A5A5;}
                &:focus{outline:none;border:2px solid #00B074;}
                &:read-only{
                  &:focus{border:none;}
                }
              }
          }
          
        `}
    }
  `,
  TotalSection: styled.div`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border: 1px solid #e7eaee;
    border-top: 0;
    border-radius: 0 0 8px 8px;
    padding: 0 24px;
    p {
      color: #001a21;
      font-size: 14px;
      font-weight: 600;
      b {
        color: #00b074;
        font-weight: 400;
        margin-right: 5px;
      }
    }
  `,
};

export interface ScrollTableProps extends TableProps {
  /** Total영역의 Total값*/
  total?: string;
  /** thead와 tbody의 테두리 유무*/
  $hasBorder?: boolean;
  /** 테이블의 최소 높이*/
  $minHeight?: string | number;
  /** 테이블의 최대 높이*/
  $maxHeight?: string | number;
}

/** 스크롤이 있는 테이블 컴포넌트*/
const ScrollTable = ({ caption, colgroup, thead, tbody, width, height, $minHeight, total, checkLength, onRemove, $hasBorder, $hasTotal, $totalBtnName, $maxHeight }: ScrollTableProps) => {
  return (
    <>
      {/* 테이블 */}
      <ScrollTableStyle.Contain
        style={{
          height: height ? height : 'auto',
          borderRadius: total ? '8px 8px 0 0' : '8px',
          minHeight: $minHeight ? $minHeight : 'inherit',
          maxHeight: $maxHeight ? $maxHeight : 'inherit',
        }}
        className={'cScroll'}
      >
        <ScrollTableStyle.Wrap width={width}>
          <caption>{caption}</caption>
          {colgroup ? (
            <colgroup>
              {colgroup.map((i, index) => {
                return <col key={index} style={{ width: i }} />;
              })}
            </colgroup>
          ) : null}
          <ScrollTableStyle.Thead $hasBorder={$hasBorder}>{thead}</ScrollTableStyle.Thead>
          <ScrollTableStyle.Tbody $hasBorder={$hasBorder} total={total}>
            {tbody}
          </ScrollTableStyle.Tbody>
        </ScrollTableStyle.Wrap>
      </ScrollTableStyle.Contain>
      {$hasTotal ? (
        <ScrollTableStyle.TotalSection>
          {total ? (
            <p>
              <b>총</b>
              {total}
            </p>
          ) : null}

          <div className="btnWrap" style={{ display: 'flex', marginLeft: 'auto', gap: '8px' }}>
            {!!checkLength ? (
              <Button $colorType={'redLine'} text={`${$totalBtnName}(${checkLength})`} icon={IcoFileRemove} $iconSize={20} $iconSpacing={6} style={{ marginLeft: 'auto' }} padding={12} height={36} fontSize={14} onClick={onRemove} />
            ) : null}
          </div>
        </ScrollTableStyle.TotalSection>
      ) : null}
    </>
  );
};

export default ScrollTable;
